<template>
	<v-layout>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Category </v-flex>
					<v-flex class="text-right">
						<v-btn
							tile
							depressed
							color="blue darken-4 text-white"
							small
							class="mx-4"
							v-if="getPermission('group::create')"
							v-on:click="createContractType"
						>
							<span class="font-size-14 font-weight-600"> <v-icon>mdi-plus</v-icon> New Category</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<v-container class="py-0">
					<v-form
						ref="categoryForm"
						v-model="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateContractSetting"
					>
						<v-row class="py-0">
							<v-col md="12" class="py-0">
								<v-simple-table>
									<tbody>
										<template v-for="(type, index) in categoryList">
											<tr :key="`category-row-${index}`" class="table-category-row">
												<td style="border: none" width="95%">
													<TextInput
														hide-details
														placeholder="Enter Category"
														:loading="pageLoading"
														:id="`category-${index}`"
														:disabled="(!getPermission('group::edit') && type.uuid ? true : false) || pageLoading"
														v-model="type.text"
														class="mb-3"
														:class="{
															required: !type.text,
														}"
														:rules="[vrules.required(type.text, 'Category')]"
													></TextInput>
													<TextAreaInput
														v-if="false"
														:rows="2"
														hide-details
														placeholder="Description"
														v-model="type.description"
														:disabled="(!getPermission('group::edit') && type.uuid ? true : false) || pageLoading"
														:loading="pageLoading"
														class="mb-3"
													></TextAreaInput>
												</td>
												<td style="border: none" width="5%">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-on:click="deleteContractType(index, type)"
																color="deep-orange"
																v-bind="attrs"
																v-if="getPermission('group::delete') || !type.uuid ? true : false"
																v-on="on"
																>mdi-delete</v-icon
															>
														</template>
														<span>Click here to delete</span>
													</v-tooltip>
												</td>
											</tr>
											<tr :key="`category-index-${index}`" v-if="false">
												<td colspan="2" style="border: none; height: 15px !important"></td>
											</tr>
										</template>
									</tbody>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close-dialog', true)"
				>
					Close
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					:loading="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="updateContractSetting"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Category"
			:delete-text="deleteText"
			v-on:success="removeGroupe"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, cloneDeep } from "lodash";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		category: {
			type: Array,
			default: () => {
				return new Array();
			},
		},
	},
	data: () => {
		return {
			formValid: true,
			pageLoading: false,
			categoryList: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
		};
	},
	methods: {
		updateContractSetting() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.categoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.categoryForm.validate()) {
				return false;
			}

			_this.pageLoading = true;

			ApiService.setHeader();
			ApiService.post(`group/bulk`, { groups: _this.categoryList })
				.then(({ data }) => {
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.$emit("close-dialog", true);
					_this.$emit("success", data);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		createContractType() {
			this.categoryList.push({
				text: null,
				description: null,
				value: null,
				uuid: null,
			});

			this.$nextTick(() => {
				const focus_element = `category-${this.categoryList.length - 1}`;
				document.getElementById(focus_element).focus();
			});
		},
		deleteContractType(index /* data */) {
			/* if (this.categoryList.length > 1) {
				if (data && data.value) {
					this.deleteDialog = true;
					this.deleteId = data.value;
					this.deleteIndex = index;
					this.deleteText = data.text;
					this.deleteURL = "group/" + data.uuid;
				} else {
					this.categoryList.splice(index, 1);
				}
			} */
			if (this.categoryList.length > 1) {
				this.categoryList.splice(index, 1);
			}
		},
		removeGroupe() {
			if (this.deleteIndex >= 0) {
				this.categoryList.splice(this.deleteIndex, 1);
				this.deleteIndex = null;
				this.deleteDialog = false;
				this.$store.dispatch(SET_LOCAL_DB);
				this.$emit("success", this.categoryList);
			}
		},
	},
	components: {
		Dialog,
		TextAreaInput,
		DeleteTemplate,
		TextInput,
	},
	mounted() {
		this.categoryList = cloneDeep(this.localDB("groups", []));
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 800;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
